import {
  Box,
  Divider,
  IconButton,
  Link,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React from 'react';
import Favicon from 'components/Favicon';
import SeeMoreButton from 'components/SeeMoreButton';
import moment from 'moment';
import { BORDER_COLOR, DARK_GREY, DARKER_GREY, LIGHT_BLACK } from 'utils/theme';
import { useEvidenceLabel } from 'pages/PlaygroundV2Page/hooks/useEvidendeLabel';
import SourceLabel from 'pages/PlaygroundV2Page/NoSourcesFoundComponent/components/SourceLabel';
import ReadAloudButton from './ReadAloudButton';
import CopyToClipboardButton from '../../../Summary/components/CopyToClipboardButton';
import FeedbackAction from '../FeedbackAction';
import FavoriteIcon from 'assets/img/icons/FavoriteIcon';
import { useDispatch } from 'react-redux';
import { setFavoriteDomains } from 'core/store/claimsV2Slice';
import { useAppSelector } from 'core/store/hooks';
import FavoriteFilledIcon from 'assets/img/icons/FavoriteFilledIcon';
import ArrowUpRightIcon from 'assets/img/icons/ArrowUpRightIcon';
import TooltipIcon from 'pages/PlaygroundV2Page/components/TooltipIcon';

export const ClaimEvidenceHeader = ({
  index,
  evidence,
}: {
  index: number;
  evidence: Source;
}) => {
  const { getLabel } = useEvidenceLabel();

  const label = getLabel(evidence);
  const theme = useTheme();

  const isMobileScreen = useMediaQuery(theme.breakpoints.down('tablet'));
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      mt={3}
      spacing={1}
    >
      <Typography
        fontSize="18px"
        fontWeight="500"
        my={2}
        display="flex"
        flexDirection="row"
      >
        <Box
          component="span"
          fontWeight="400"
          fontSize="16px"
          paddingRight="4px"
          paddingTop="2px"
        >
          {index + 1}.
        </Box>{' '}
        <Box
          component="span"
          sx={{
            fontStyle: evidence.title ? 'normal' : 'italic',
            color: evidence.title ? 'inherit' : DARK_GREY,
          }}
        >
          {evidence.title || 'Headline not found'}
        </Box>
      </Typography>
      {!isMobileScreen && (
        <Box
          sx={{
            padding: '12px',
          }}
        >
          <SourceLabel label={label} />
        </Box>
      )}
    </Stack>
  );
};

export const ClaimEvidenceBody = ({
  evidence,
  claim,
  expandedEvidence,
  setExpandedEvidence,
}: {
  evidence: Source;
  claim: Claim;
  expandedEvidence: string | null;
  setExpandedEvidence: (name: string | null) => void;
}) => {
  const textToRead = `${evidence.title ?? 'Headline not found'}. ${
    evidence.evidenceSnippet ?? 'Snippet not found'
  }`;
  const textToCopy = evidence.url ?? '';

  return (
    <Box
      sx={{
        background: 'rgba(241, 242, 251, 0.6)',
        borderRadius: '8px',
        marginLeft: {
          mobile: 0,
          tablet: 0,
          desktop: '20px !important',
        },

        marginRight: {
          mobile: 0,
          tablet: 0,
          desktop: '20px !important',
        },
        padding: {
          mobile: '12px',
          tablet: '16px',
          desktop: '24px 117px 24px 40px',
        },
      }}
    >
      <SeeMoreButton
        name={evidence.title || 'Headline not found'}
        text={evidence.evidenceSnippet || 'Snippet not found'}
        expandedEvidence={expandedEvidence}
        setExpandedEvidence={setExpandedEvidence}
      />
      <Box display="flex" flexDirection="row">
        <ReadAloudButton text={textToRead} />
        <CopyToClipboardButton textToCopy={textToCopy} />
        <FeedbackAction claim={claim} source={evidence} />
      </Box>
    </Box>
  );
};

export const ClaimEvidenceFooter = ({ evidence }: { evidence: Source }) => {
  const { getLabel } = useEvidenceLabel();

  const label = getLabel(evidence);
  const theme = useTheme();
  const dispatch = useDispatch();
  const favoriteDomains = useAppSelector(
    (state) => state.claimsV2.favoriteDomains
  );
  const isFavorite =
    evidence.domain && (favoriteDomains || []).includes(evidence.domain);

  const handleFavoriteButtonClick = () => {
    if (!evidence.domain) return;

    const updatedDomains = favoriteDomains?.includes(evidence.domain)
      ? favoriteDomains.filter((domain) => domain !== evidence.domain)
      : [...(favoriteDomains ?? []), evidence.domain];

    dispatch(setFavoriteDomains(updatedDomains));
  };

  const isMobileScreen = useMediaQuery(theme.breakpoints.down('tablet'));

  const tootlipTitle = () => {
    if (trustScore > 40) {
      return 'Reuters high trusted score signals that the source is reliable and that the content is validated by trusted third-party fact-checkers.';
    } else {
      return 'A low score suggests a higher likelihood of misinformation or bias and how often their content is validated by trusted third-party fact-checkers.';
    }
  };
  const trustScore =
    evidence?.domain_reliability?.reuters_trust_score?.trust_score || 0;

  const reliabilityData = [
    {
      key: 'reuters_trust_score',
      label: 'Reuters Trust Score:',
      value: evidence.domain_reliability?.reuters_trust_score?.trust_score,
      source: evidence.domain_reliability?.reuters_trust_score?.source,
      tooltip: (
        <Box>
          <Typography fontSize="12px" color="#ffffff">
            {tootlipTitle()}
          </Typography>
          <Link
            href={evidence.domain_reliability?.reuters_trust_score?.source}
            target="_blank"
            rel="noopener noreferrer"
            sx={{
              fontSize: '12px',
              marginTop: '4px',
              display: 'flex',
              alignItems: 'center',
              gap: '4px',
              color: '#FFFFFFB2',
              textDecorationColor: '#FFFFFFB2',
            }}
          >
            Reuters digital-news-report/2024
            <ArrowUpRightIcon />
          </Link>
        </Box>
      ),
    },
    {
      key: 'bias_data',
      label: 'Political Bias:',
      value: evidence.domain_reliability?.bias_data?.bias,
      source: evidence.domain_reliability?.bias_data?.source,
      tooltip: (
        <Typography fontSize="12px" color="#ffffff">
          View supporting, disputing, and mixed claims, along with potential
          political biases influencing the analysis.
        </Typography>
      ),
    },
  ];

  return (
    <Box
      sx={{
        paddingLeft: {
          desktop: '20px',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexWrap: {
            mobile: 'wrap',
            tablet: 'no-wrap',
            desktop: 'no-wrap',
          },
          gap: '10px',
        }}
      >
        {evidence.domain && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              paddingRight: '10px',
              gap: '10px',
            }}
          >
            <Box
              sx={{
                borderRight: `1px solid ${BORDER_COLOR}`,
                paddingRight: '10px',
              }}
            >
              <span>
                {evidence.domain && (
                  <Tooltip
                    title={isFavorite ? 'Remove Favorite' : 'Favorite Domain'}
                    arrow
                  >
                    <IconButton
                      size="small"
                      onClick={handleFavoriteButtonClick}
                    >
                      {isFavorite ? (
                        <FavoriteFilledIcon data-testid="favorite-filled-icon" />
                      ) : (
                        <FavoriteIcon data-testid="favorite-icon" />
                      )}
                    </IconButton>
                  </Tooltip>
                )}
              </span>
            </Box>
            <Favicon rootDomain={evidence.domain} width={24} height={24} />
            <Link
              sx={{
                fontSize: '12px',
                ml: '8px',
              }}
              href={evidence.url}
              target="_blank"
            >
              {evidence.domainName || evidence.domain}
            </Link>
          </Box>
        )}

        {evidence.publishDate && (
          <Box
            className="item"
            sx={{
              borderLeft: `1px solid ${BORDER_COLOR}`,
              paddingLeft: '10px',
            }}
          >
            <Typography fontSize="12px" color={DARKER_GREY}>
              {moment(evidence.publishDate).year()}
            </Typography>
          </Box>
        )}

        {(evidence.citationCount ?? 0) > 0 && (
          <Box
            className="item"
            sx={{
              borderLeft: `1px solid ${BORDER_COLOR}`,
              paddingLeft: '10px',
            }}
          >
            <Typography fontSize="12px" color={DARKER_GREY}>
              <span
                style={{
                  textDecoration: 'underline',
                  color: LIGHT_BLACK,
                }}
              >
                {evidence.citationCount}
              </span>{' '}
              citations
            </Typography>
          </Box>
        )}

        {evidence.authors?.length ? (
          <Box
            className="item"
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
              paddingLeft: '10px',
              borderLeft: `1px solid ${BORDER_COLOR}`,
            }}
          >
            <Typography fontSize="12px" color={DARKER_GREY}>
              <span>{evidence.authors[0]}</span>{' '}
              {evidence.authors.length > 1 && (
                <>
                  +{' '}
                  <span
                    style={{
                      textDecoration: 'underline',
                      color: LIGHT_BLACK,
                    }}
                  >
                    {evidence.authors.length - 1} authors
                  </span>
                </>
              )}
            </Typography>
          </Box>
        ) : null}

        {reliabilityData.map(
          ({ key, label, value, source, tooltip }) =>
            value && (
              <Box
                key={key}
                className="item"
                sx={{
                  borderLeft: `1px solid ${BORDER_COLOR}`,
                  paddingLeft: '10px',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '6px',
                }}
              >
                <Link
                  sx={{ fontSize: '12px', fontFamily: 'Inter' }}
                  href={source}
                  target="_blank"
                  rel="noopener noreferrer"
                  underline="none"
                >
                  {label}
                </Link>
                <Typography
                  fontSize="12px"
                  color={DARKER_GREY}
                  lineHeight="14px"
                >
                  {value}
                </Typography>
                <TooltipIcon tooltip={tooltip} />
              </Box>
            )
        )}
      </Box>

      {isMobileScreen && (
        <>
          <Divider
            flexItem
            sx={{
              width: '100%',
              mt: '20px',
              mb: '16px',
              borderColor: BORDER_COLOR,
            }}
          />
          <Box sx={{ mt: '10px' }}>
            <SourceLabel label={label} />
          </Box>
        </>
      )}
    </Box>
  );
};
