import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Box,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { setSearchPrompt } from 'core/store/claimsV2Slice';
import { promptText } from '../helpers';
import SuggestedSearches from '../NoSourcesFoundComponent/components/SuggestedSearches';
import { useAppSelector } from 'core/store/hooks';
import { BORDER_COLOR, LAVENDER_STEEL, LIGHT_BLACK } from 'utils/theme';
import CloseIcon from 'assets/img/icons/CloseIcon';
import YellowButton from 'components/buttons/YellowButton';
import { useGenerate } from '../hooks/useGenerate';

const RelatedClaims = () => {
  const dispatch = useDispatch();
  const storedPrompt = useAppSelector((state) => state.claimsV2.searchPrompt);
  const claim = useAppSelector((state) => state.claimsV2.searchValue);

  const getGeneratedClaims = useGenerate();

  const [customPrompt, setCustomPrompt] = useState(storedPrompt || promptText);
  const suggestedSearchesFromRedux = useAppSelector(
    (state) => state.claimsV2.generatedClaims
  );
  const [suggestedSearches, setSuggestedSearches] = useState<string[]>([]);

  React.useEffect(() => {
    setSuggestedSearches(suggestedSearchesFromRedux);
  }, [suggestedSearchesFromRedux]);

  const handleUpdatePrompt = () => {
    dispatch(setSearchPrompt(customPrompt.trim()));
  };
  const handleRegenerateClaims = () => {
    getGeneratedClaims(claim);
  };

  const isPromptChanged = customPrompt.trim() !== storedPrompt?.trim();

  return (
    <Box>
      <Stack spacing={2} mb={2} mt={2}>
        <Typography fontWeight="500">Customize Search Prompt</Typography>
        <TextField
          fullWidth
          multiline
          variant="outlined"
          value={customPrompt}
          onChange={(e) => setCustomPrompt(e.target.value)}
          sx={{
            background: 'white',
            paddingX: { mobile: '16px', tablet: '30px', desktop: '30px' },
            paddingY: { mobile: '16px', tablet: '25px', desktop: '25px' },
            '& fieldset': { border: 'none' },
            borderRadius: '12px',
            border: `1px solid ${BORDER_COLOR}`,
            boxSizing: 'border-box',
          }}
          InputProps={{
            style: {
              padding: 0,
              fontSize: '18px',
              color: LIGHT_BLACK,
              alignItems: 'start',
            },
            endAdornment: (
              <InputAdornment position="end">
                <Stack direction="row" spacing={2}>
                  <IconButton
                    type="button"
                    sx={{
                      width: '30px',
                      height: '30px',
                    }}
                    aria-label="delete claim"
                    onClick={() => {
                      setCustomPrompt('');
                    }}
                  >
                    <CloseIcon stroke={LAVENDER_STEEL} />
                  </IconButton>
                </Stack>
              </InputAdornment>
            ),
          }}
        />
        <YellowButton
          onClick={handleUpdatePrompt}
          disabled={!customPrompt.trim() || !isPromptChanged}
        >
          Update Prompt
        </YellowButton>

        <YellowButton
          onClick={handleRegenerateClaims}
          disabled={!customPrompt.trim()}
        >
          Regenerate Related Claims
        </YellowButton>
      </Stack>

      {suggestedSearches.length > 0 && (
        <SuggestedSearches
          suggestedSearchesData={suggestedSearches}
          title="Related Claims:"
        />
      )}
    </Box>
  );
};

export default RelatedClaims;
