import React, { useEffect, useState } from 'react';
import { Stack, Snackbar } from '@mui/material';
import { useDispatch } from 'react-redux';
import { setSearchValue } from 'core/store/claimsV2Slice';
import SuggestedButton from './components/SuggestedButton';
import { useCheckClaim } from '../hooks/useCheckClaim';
import { suggestedClaims } from 'data/suggestedClaims';
import { shuffleArray } from '../helpers';
import { useGenerate } from 'pages/PlaygroundV2Page/hooks/useGenerate';
import { useAuth0 } from '@auth0/auth0-react';

const SuggestedClaimButtons: React.FC = () => {
  const checkClaim = useCheckClaim();
  const getGeneratedClaims = useGenerate();
  const { isAuthenticated } = useAuth0();

  const dispatch = useDispatch();

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const handleClaimClick = (claim: string) => {
    dispatch(setSearchValue(claim));
    if (!isAuthenticated) {
      setSnackbarMessage('Please log in or sign up to check a claim.');
      setSnackbarOpen(true);
      return;
    }
    checkClaim(claim);
    getGeneratedClaims(claim);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const [randomizedClaims, setRandomizedClaims] = useState<string[]>([]);

  useEffect(() => {
    setRandomizedClaims(shuffleArray(suggestedClaims));
  }, []);

  const MAX_CLAIMS = 4;
  const MAX_LENGTH = 50;
  let hasLongClaim = false;

  const filteredClaims = randomizedClaims
    .filter((claim) => {
      const isLongClaim = claim.length > MAX_LENGTH;

      if (isLongClaim && hasLongClaim) {
        return false;
      }

      if (isLongClaim) {
        hasLongClaim = true;
      }

      return true;
    })
    .slice(0, MAX_CLAIMS);

  return (
    <>
      <Stack
        mt={5}
        direction="row"
        flexWrap="wrap"
        useFlexGap
        spacing={{ xs: 3 }}
        justifyContent="flex-start"
      >
        {filteredClaims.map((claim, index) => (
          <SuggestedButton
            key={index}
            claim={claim}
            onClick={() => handleClaimClick(claim)}
          />
        ))}
      </Stack>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      />
    </>
  );
};

export default SuggestedClaimButtons;
